import { Spin } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'

import BalanceCard from '../../common/balanceCard/BalanceCard'
import { useBalance, usePaymentHistory } from '../../queries/queries'
import { locales } from '../../utils/constants/locales'
import { isEmptyArr } from '../../utils/helpers/isEmptyArr'

type props = {
  selectedPaymentId: number
}

const DetailedClientRight: React.FC<props> = ({ selectedPaymentId }) => {
  const params = useParams()
  const { data } = useBalance(params?.id)
  const { data: historyData, isLoading: historyLoading } =
    usePaymentHistory(selectedPaymentId)

  // title
  const title = () => {
    if (!isEmptyArr(historyData?.histories || [])) {
      return 'Amallari tarixi'
    }
    return "Amallar tarixi yo'q"
  }

  return (
    <div className="detailed_client_right">
      <div className="total_balance">
        {data?.map((item) => (
          <BalanceCard key={item.key} data={item} />
        ))}
      </div>
      {selectedPaymentId ? (
        <Spin spinning={historyLoading}>
          <div className="balance">
            <h2 className="title">To'lovdan keyingi balans</h2>
            <p>
              {historyData?.balance?.toLocaleString(locales.RU)}{' '}
              {historyData?.currency?.toUpperCase()}
            </p>
          </div>
          <div className="history">
            <h2 className="title">{title()}</h2>
            {historyData?.histories?.map((item, index) => (
              <div className="history_card" key={index}>
                <p>{item.information}</p>
                <span>{item.date}</span>
              </div>
            ))}
          </div>
        </Spin>
      ) : null}
    </div>
  )
}

export default DetailedClientRight
