import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sliceNames } from '../../utils/constants/sliceNames'
import { authService } from '../../utils/helpers/authService'

const initialState: {
  isAuth: boolean
} = {
  isAuth: authService.isAuth(),
}

export const userReducer = createSlice({
  name: sliceNames.PRODUCTS,
  initialState,
  reducers: {
    setAuthed: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
  },
})

export default userReducer.reducer
