import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import $api from '../request/axiosInstance'
import {
  balanceEndpoints,
  clientEndpoints,
  historyEndpoints,
  paymentEndpoints,
  userEndpoints,
} from '../utils/constants/api'
import { currencies } from '../utils/constants/currencies'
import { queryParamsKeys } from '../utils/constants/queryParamsKeys'
import { urls } from '../utils/constants/urls'
import { addPrivateEndpoint } from '../utils/helpers/addPrivateEndpoint'
import { BalanceType, HistoryRes } from '../utils/models/BalanceType'
import { ClientsType } from '../utils/models/ClientsType'
import { PaymentType } from '../utils/models/PaymentType'
import { ProfileType } from '../utils/models/ProfileType'
import { queryNames } from './queryNames'

// get clients
export function useClients(
  page: number,
  searchTxt: string,
  sort: {
    type: string
    by: string
  } | null
) {
  let url = `${clientEndpoints.INDEX}?${queryParamsKeys.PAGE}=${page}`

  // search
  if (searchTxt) url += `&${queryParamsKeys.SEARCH}=${searchTxt}`

  // sart
  if (sort) url += `&${queryParamsKeys.SORT}=${sort.by}_${sort.type}`

  return useQuery<ClientsType>(
    [queryNames.CLIENTS, page, searchTxt, addPrivateEndpoint(), sort],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}

// get balance
export function useBalance(clientId?: string) {
  let url = `${balanceEndpoints.INDEX}`

  if (clientId) {
    url += `?${queryParamsKeys.CLIENT_ID}=${clientId}`
  }

  return useQuery<BalanceType[]>(
    [queryNames.BALANCE, clientId, addPrivateEndpoint()],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}

// get payments of client
export function usePayments(
  clientId: string,
  page: number,
  size: number,
  sort: { type: string; by: string } | null
) {
  let url = `${paymentEndpoints.INDEX}?${queryParamsKeys.CLIENT_ID}=${clientId}&${queryParamsKeys.PAGE}=${page}&${queryParamsKeys.SIZE}=${size}`

  // sart
  if (sort) url += `&${queryParamsKeys.SORT}=${sort.by}_${sort.type}`

  return useQuery<PaymentType>(
    [queryNames.PAYMENTS, clientId, page, size, addPrivateEndpoint(), sort],
    async () => {
      const res = await $api.get(url)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!clientId,
    }
  )
}

// get usd amount
export function useUsdAmount() {
  return useQuery<number>(
    [queryNames.USD_AMOUNT, addPrivateEndpoint()],
    async () => {
      const res = await axios.get(urls.USD_AMOUNT)

      let usdAmount = 0

      res.data.forEach((item: { code: string; nbu_cell_price: string }) => {
        if (item?.code === currencies.USD.toUpperCase()) {
          usdAmount = +item?.nbu_cell_price
        }
      })

      return usdAmount
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}

// get history of payment
export function usePaymentHistory(paymentId: number) {
  return useQuery<HistoryRes>(
    [queryNames.PAYMENT_HISTORY, paymentId, addPrivateEndpoint()],
    async () => {
      const res = await $api.get(
        `${historyEndpoints.INDEX}?${queryParamsKeys.PAYMENT_ID}=${paymentId}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!paymentId,
    }
  )
}

// get profile
export function useProfile(modalVisible: boolean) {
  return useQuery<ProfileType>(
    [queryNames.PROFILE, modalVisible],
    async () => {
      const res = await $api.get(userEndpoints.INDEX)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: modalVisible,
    }
  )
}
