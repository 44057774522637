import React from 'react'
import Header from '../components/header/Header'
import Redirector from '../routing/Redirector'

type props = {
  children: React.ReactNode
}

const MainLayout: React.FC<props> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Redirector />
    </>
  )
}

export default MainLayout
